import request from '@/utils/request'

//  获取评估问卷列表
export function getCrfs(params) {
    params.dataType='noKey';
    return request({
        url: '/crf/question_list',
        method: 'get',
        params:params
      })
  }

//  获取评估问卷列表
export function getEmptyCrfs(params) {
    params.dataType='noKey';
    return request({
        url: '/weixin_itemdataorders/send_info_empty.json',
        method: 'get',
        params:params
      })
  }

//  获取评估问卷列表
export function getDiarys(params) {
    params.dataType='noKey';
    return request({
        url: '/crf/send_info_diary',
        method: 'get',
        params:params
      })
  }


//  部位图片信息
export function getBwPicInfo(params) {

    let bwInfo = {}
    bwInfo["fmd_jbvas"] =null
    bwInfo["fmd_jgvas"] =null
    bwInfo["fmd_sbbvas"] =null
    bwInfo["fmd_xbybvas"] =null
    bwInfo["fmd_zgjvas"] =null
    bwInfo["fmd_swgjvas"] =null
    bwInfo["fmd_kgjvas"] =null
    bwInfo["fmd_xgjvas"] =null
    bwInfo["fmd_hgjvas"] =null
    bwInfo["fmd_xxgjvas"] =null

// 颈部信息
    let bwImgs = ["jbvas1.png","jbvas2.png","jbvas3.png"]
    let bwAreaHash = {}
    let bwAreas = new Array()
    bwAreas[0]= {"x":78,"y":100,"r":26,"sx":1.25,"sy":1,"ra":0,"type":0}
    bwAreas[1]= {"x":181,"y":100,"r":26,"sx":1.25,"sy":1,"ra":0,"type":0}
    bwAreas[2]= {"x":59,"y":166,"r":27,"sx":1.41,"sy":1,"ra":0,"type":0}
    bwAreas[3]= {"x":169,"y":164,"r":27,"sx":1.41,"sy":1,"ra":0,"type":0}
    bwAreas[4]= {"x":161,"y":40,"r":27,"sx":1,"sy":3.1,"ra":0,"type":0}
    bwAreaHash["jbvas1.png"] = bwAreas

    bwAreas = new Array()
    bwAreas[6]= {"x":142,"y":75,"r":35,"sx":1.39,"sy":1,"ra":0,"type":0}
    bwAreas[8]= {"x":123,"y":150,"r":45,"sx":1.1,"sy":1,"ra":0,"type":0}
    bwAreaHash["jbvas2.png"] = bwAreas

    bwAreas = new Array()
    bwAreas[5]= {"x":110,"y":87,"r":32,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[7]= {"x":171,"y":174,"r":41,"sx":1.24,"sy":1,"ra":0,"type":0}
    bwAreaHash["jbvas3.png"] = bwAreas

    bwInfo["fmd_jbvas"]={"imgs":bwImgs,"areas":bwAreaHash}


// 肩部信息
    bwImgs = ["jgjvas1.png","jgjvas2.png","jgjvas3.png","jgjvas4.png"]
    bwAreaHash = {}
    bwAreas = new Array()

    bwAreas[0]= {"x":160,"y":25,"r":11,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[1]= {"x":80,"y":30,"r":12,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[2]= {"x":181,"y":19,"r":12,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[3]= {"x":56,"y":24,"r":11,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[4]= {"x":175,"y":45,"r":14,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[5]= {"x":67,"y":50,"r":13,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[6]= {"x":205,"y":19,"r":11,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[7]= {"x":32,"y":28,"r":11,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreaHash["jgjvas1.png"] = bwAreas

    bwAreas = new Array()
    bwAreas[0]= {"x":55,"y":33,"r":13,"sx":1.6,"sy":1,"ra":0,"type":0}
    bwAreas[1]= {"x":155,"y":36,"r":13,"sx":1.6,"sy":1,"ra":0,"type":0}
    bwAreas[2]= {"x":52,"y":43,"r":16,"sx":1,"sy":1.1,"ra":0,"type":0}
    bwAreas[3]= {"x":287,"y":43,"r":18,"sx":1,"sy":1.1,"ra":0,"type":0}
    bwAreas[6]= {"x":42,"y":69,"r":12,"sx":1,"sy":1.2,"ra":0,"type":0}
    bwAreas[7]= {"x":299,"y":68,"r":12,"sx":1,"sy":1.2,"ra":0,"type":0}
    bwAreas[8]= {"x":63,"y":68,"r":16,"sx":1.3,"sy":1,"ra":0,"type":0}
    bwAreas[9]= {"x":195,"y":67,"r":16,"sx":1.3,"sy":1,"ra":0,"type":0}
    bwAreaHash["jgjvas2.png"] = bwAreas

    bwAreas = new Array()
    bwAreas[1]= {"x":170,"y":166,"r":22,"sx":1,"sy":1.12,"ra":0,"type":0}
    bwAreas[3]= {"x":132,"y":239,"r":24,"sx":1.3,"sy":1,"ra":0,"type":0}
    bwAreas[5]= {"x":235,"y":180,"r":25,"sx":1,"sy":1.3,"ra":0,"type":0}
    bwAreas[7]= {"x":120,"y":289,"r":23,"sx":1.45,"sy":1,"ra":0,"type":0}
    bwAreas[9]= {"x":114,"y":218,"r":22,"sx":1,"sy":1.14,"ra":0,"type":0}
    bwAreaHash["jgjvas3.png"] = bwAreas

    bwAreas = new Array()
    bwAreas[0]= {"x":206,"y":57,"r":18,"sx":1,"sy":1.2,"ra":0,"type":0}
    bwAreas[2]= {"x":182,"y":93,"r":14,"sx":1.3,"sy":1,"ra":0,"type":0}
    bwAreas[4]= {"x":205,"y":110,"r":15,"sx":1,"sy":1,"ra":0,"type":0}
    bwAreas[6]= {"x":186,"y":124,"r":16,"sx":1.3,"sy":1,"ra":0,"type":0}
    bwAreas[8]= {"x":203,"y":70,"r":18,"sx":1.3,"sy":1,"ra":0,"type":0}
    bwAreaHash["jgjvas4.png"] = bwAreas

    bwInfo["fmd_jgvas"]={"imgs":bwImgs,"areas":bwAreaHash}


// 上背部信息
    bwImgs = ["sbbvas1.png"]
    bwAreaHash = {}
    bwAreas = new Array()

    bwAreas[0]= {"x":68,"y":63,"w":68,"h":85,"ra":0,"type":1}
    bwAreas[1]= {"x":202,"y":60,"w":65,"h":90,"ra":0,"type":1}
    bwAreas[2]= {"x":140,"y":42,"w":55,"h":125,"ra":0,"type":1}
    bwAreaHash["sbbvas1.png"] = bwAreas

    bwInfo["fmd_sbbvas"]={"imgs":bwImgs,"areas":bwAreaHash}


// 下背部信息
    bwImgs = ["xbybvas1.png"]
    bwAreaHash = {}
    bwAreas = new Array()

    bwAreas[0]= {"x":75,"y":50,"w":60,"h":90,"ra":0,"type":1}
    bwAreas[1]= {"x":203,"y":52,"w":56,"h":85,"ra":0,"type":1}
    bwAreas[2]= {"x":143,"y":35,"w":52,"h":118,"ra":0,"type":1}
    bwAreaHash["xbybvas1.png"] = bwAreas

    bwInfo["fmd_xbybvas"]={"imgs":bwImgs,"areas":bwAreaHash}

// 肘关节信息
    bwImgs = ["zgjvas1.png","zgjvas2.png"]
    bwAreaHash = {}
    bwAreas = new Array()

    bwAreas[0]= {"x":124,"y":79,"w":17,"h":47,"ra":-17,"type":1}
    bwAreas[1]= {"x":38,"y":54,"w":17,"h":48,"ra":17,"type":1}
    bwAreas[2]= {"x":135,"y":75,"w":17,"h":47,"ra":-17,"type":1}
    bwAreas[3]= {"x":27,"y":50,"w":17,"h":48,"ra":17,"type":1}
    bwAreas[4]= {"x":145,"y":69,"w":17,"h":48,"ra":-17,"type":1}
    bwAreas[5]= {"x":17,"y":45,"w":17,"h":50,"ra":17,"type":1}
    bwAreaHash["zgjvas1.png"] = bwAreas

    bwAreas = new Array()

    bwAreas[0]= {"x":33,"y":67,"w":16,"h":53,"ra":5,"type":1}
    bwAreas[1]= {"x":116,"y":90,"w":18,"h":52,"ra":-21,"type":1}
    bwAreas[4]= {"x":13,"y":60,"w":16,"h":53,"ra":5,"type":1}
    bwAreas[5]= {"x":137,"y":82,"w":16,"h":53,"ra":-21,"type":1}
    bwAreas[6]= {"x":23,"y":63,"w":16,"h":53,"ra":5,"type":1}
    bwAreas[7]= {"x":127,"y":87,"w":18,"h":52,"ra":-21,"type":1}
    bwAreaHash["zgjvas2.png"] = bwAreas

    bwInfo["fmd_zgjvas"]={"imgs":bwImgs,"areas":bwAreaHash}


// 手腕关节信息
    bwImgs = ["swgjvas1.png","swgjvas2.png","swgjvas3.png","swgjvas4.png"]
    bwAreaHash = {}
    bwAreas = new Array()

    bwAreas[0]= {"x":48,"y":106,"w":73,"h":37,"ra":0,"type":1}
    bwAreas[2]= {"x":48,"y":13,"w":73,"h":37,"ra":0,"type":1}
    bwAreas[4]= {"x":48,"y":55,"w":73,"h":45,"ra":0,"type":1}
    bwAreas[6]= {"x":97,"y":17,"w":48,"h":145,"ra":-10,"type":1}
    bwAreas[8]= {"x":123,"y":19,"w":62,"h":133,"ra":-10,"type":1}
    bwAreaHash["swgjvas1.png"] = bwAreas

    bwAreas = new Array()
    bwAreas[0]= {"x":13,"y":27,"w":66,"h":30,"ra":-8,"type":1}
    bwAreas[2]= {"x":13,"y":62,"w":70,"h":28,"ra":-8,"type":1}
    bwAreas[4]= {"x":13,"y":43,"w":70,"h":35,"ra":-8,"type":1}
    bwAreas[6]= {"x":90,"y":0,"w":63,"h":123,"ra":17,"type":1}
    bwAreas[8]= {"x":122,"y":5,"w":90,"h":120,"ra":12,"type":1}
    bwAreaHash["swgjvas2.png"] = bwAreas

    bwAreas = new Array()
    bwAreas[1]= {"x":11,"y":40,"w":68,"h":36,"ra":-10,"type":1}
    bwAreas[3]= {"x":17,"y":78,"w":68,"h":36,"ra":-10,"type":1}
    bwAreas[5]= {"x":14,"y":58,"w":68,"h":36,"ra":-10,"type":1}
    bwAreas[7]= {"x":85,"y":10,"w":50,"h":150,"ra":10,"type":1}
    bwAreas[9]= {"x":114,"y":4,"w":64,"h":138,"ra":8.5,"type":1}
    bwAreaHash["swgjvas3.png"] = bwAreas

// 右侧区域1:254,59,63,26,0
// 右侧区域2:254,91,67,34,0
// 右侧区域3:254,129,63,25,0
    bwAreas = new Array()
    bwAreas[1]= {"x":254,"y":59,"w":63,"h":26,"ra":0,"type":1}
    bwAreas[3]= {"x":254,"y":129,"w":63,"h":25,"ra":0,"type":1}
    bwAreas[5]= {"x":254,"y":91,"w":67,"h":34,"ra":0,"type":1}
    bwAreas[7]= {"x":53,"y":40,"w":52,"h":122,"ra":-14,"type":1}
    bwAreas[9]= {"x":-5,"y":38.5,"w":113,"h":120,"ra":-13.3,"type":1}
    bwAreaHash["swgjvas4.png"] = bwAreas

    bwInfo["fmd_swgjvas"]={"imgs":bwImgs,"areas":bwAreaHash}


// 髋关节信息
    bwImgs = ["kgjvas1.png","kgjvas2.png"]
    bwAreaHash = {}
    bwAreas = new Array()

    bwAreas[0]= {"x":140,"y":-23,"r":26,"sx":1,"sy":1.6,"ra":46,"type":0}
    bwAreas[1]= {"x":37,"y":6,"r":27,"sx":1.5,"sy":1,"ra":53,"type":0}
    bwAreas[2]= {"x":312,"y":54,"r":21,"sx":1,"sy":1.5,"ra":0,"type":0}
    bwAreas[3]= {"x":22,"y":55,"r":21,"sx":1,"sy":1.5,"ra":0,"type":0}
    bwAreas[4]= {"x":127,"y":12,"r":23,"sx":1,"sy":1.8,"ra":46,"type":0}
    bwAreas[5]= {"x":31,"y":58,"r":21,"sx":1,"sy":1.8,"ra":-40,"type":0}
    bwAreas[6]= {"x":149,"y":198,"r":30,"sx":1.7,"sy":1,"ra":0,"type":0}
    bwAreas[7]= {"x":45,"y":198,"r":30,"sx":1.7,"sy":1,"ra":0,"type":0}
    bwAreaHash["kgjvas1.png"] = bwAreas

    bwAreas = new Array()
    bwAreas[2]= {"x":47,"y":47,"r":24,"sx":1,"sy":1.7,"ra":0,"type":0}
    bwAreas[3]= {"x":305,"y":45,"r":24,"sx":1,"sy":1.7,"ra":0,"type":0}
    bwAreas[8]= {"x":50,"y":38,"r":22,"sx":1,"sy":1.8,"ra":-35,"type":0}
    bwAreas[9]= {"x":124,"y":-5,"r":22,"sx":1,"sy":1.8,"ra":40,"type":0}
    bwAreas[10]= {"x":115,"y":123,"r":35,"sx":1,"sy":1.2,"ra":0,"type":0}
    bwAreas[11]= {"x":240,"y":121,"r":35,"sx":1,"sy":1.2,"ra":0,"type":0}
    bwAreas[12]= {"x":177,"y":22,"r":22,"sx":1,"sy":2,"ra":0,"type":0}
    bwAreaHash["kgjvas2.png"] = bwAreas

    bwInfo["fmd_kgjvas"]={"imgs":bwImgs,"areas":bwAreaHash}


// 膝关节信息
    bwImgs = ["xgjvas1.png","xgjvas2.png"]
    bwAreaHash = {}
    bwAreas = new Array()

    bwAreas[0]= {"x":270,"y":50,"r":15,"sx":1,"sy":2.6,"ra":0,"type":0}
    bwAreas[1]= {"x":75,"y":48,"r":15,"sx":1,"sy":2.6,"ra":0,"type":0}
    bwAreas[2]= {"x":143,"y":90,"r":17,"sx":1.55,"sy":1,"ra":0,"type":0}
    bwAreas[3]= {"x":78,"y":86,"r":17,"sx":1.55,"sy":1,"ra":0,"type":0}
    bwAreas[4]= {"x":186,"y":41,"r":11,"sx":1,"sy":2.5,"ra":0,"type":0}
    bwAreas[5]= {"x":166,"y":41,"r":12,"sx":1,"sy":2.5,"ra":0,"type":0}
    bwAreas[6]= {"x":147,"y":130,"r":18,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[7]= {"x":82,"y":125,"r":18,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[8]= {"x":155,"y":178,"r":17,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[9]= {"x":80,"y":162,"r":17,"sx":1.5,"sy":1,"ra":0,"type":0}
    bwAreas[10]= {"x":81,"y":64,"r":11,"sx":1,"sy":2,"ra":-14,"type":0}
    bwAreas[11]= {"x":91,"y":49,"r":11,"sx":1,"sy":2,"ra":10,"type":0}
    bwAreaHash["xgjvas1.png"] = bwAreas

    bwAreas = new Array()
    bwAreas[0]= {"x":30,"y":97,"w":40,"h":84,"ra":0,"type":1}
    bwAreas[1]= {"x":268,"y":97,"w":40,"h":86,"ra":0,"type":1}
    bwAreas[4]= {"x":125,"y":95,"w":41,"h":70,"ra":0,"type":1}
    bwAreas[5]= {"x":170,"y":95,"w":41,"h":70,"ra":0,"type":1}
    bwAreas[10]= {"x":155,"y":133,"r":13,"sx":1,"sy":1.4,"ra":0,"type":0}
    bwAreas[11]= {"x":179,"y":133,"r":13,"sx":1,"sy":1.4,"ra":0,"type":0}
    bwAreas[12]= {"x":76,"y":93,"w":41,"h":96,"ra":0,"type":1}
    bwAreas[13]= {"x":217,"y":93,"w":41,"h":96,"ra":0,"type":1}
    bwAreaHash["xgjvas2.png"] = bwAreas

    bwInfo["fmd_xgjvas"]={"imgs":bwImgs,"areas":bwAreaHash}
    bwInfo["fmd_xxgjvas"]={"imgs":bwImgs,"areas":bwAreaHash}

// 踝关节信息
    bwImgs = ["hgjvas1.png","hgjvas2.png"]
    bwAreaHash = {}
    bwAreas = new Array()

    bwAreas[0]= {"x":231,"y":58,"w":21,"h":56,"ra":0,"type":1}
    bwAreas[1]= {"x":105,"y":58,"w":21,"h":56,"ra":0,"type":1}
    bwAreas[2]= {"x":179,"y":54,"w":20,"h":58,"ra":0,"type":1}
    bwAreas[3]= {"x":159,"y":54,"w":20,"h":58,"ra":0,"type":1}
    bwAreas[4]= {"x":201,"y":50,"w":26,"h":58,"ra":0,"type":1}
    bwAreas[5]= {"x":130,"y":50,"w":26,"h":60,"ra":0,"type":1}
    bwAreas[6]= {"x":75,"y":83,"w":69,"h":32,"ra":-20,"type":1}
    bwAreas[7]= {"x":72,"y":44,"w":62,"h":31,"ra":25,"type":1}
    bwAreas[8]= {"x":71,"y":100,"w":80,"h":30,"ra":-20,"type":1}
    bwAreas[9]= {"x":63,"y":60,"w":80,"h":27,"ra":25,"type":1}
    bwAreas[10]= {"x":69,"y":117,"w":92,"h":37,"ra":-20,"type":1}
    bwAreas[11]= {"x":55,"y":74,"w":85,"h":40,"ra":25,"type":1}
    bwAreaHash["hgjvas1.png"] = bwAreas

    bwAreas = new Array()
    bwAreas[0]= {"x":74,"y":115,"w":29,"h":90,"ra":0,"type":1}
    bwAreas[1]= {"x":232,"y":116,"w":29,"h":90,"ra":0,"type":1}
    bwAreas[2]= {"x":143,"y":98,"w":22,"h":90,"ra":0,"type":1}
    bwAreas[3]= {"x":167,"y":98,"w":22,"h":90,"ra":0,"type":1}
    bwAreas[12]= {"x":106,"y":103,"w":34,"h":110,"ra":0,"type":1}
    bwAreas[13]= {"x":191,"y":103,"w":34,"h":110,"ra":0,"type":1}
    bwAreaHash["hgjvas2.png"] = bwAreas

    bwInfo["fmd_hgjvas"]={"imgs":bwImgs,"areas":bwAreaHash}

    return bwInfo[params]
  }
